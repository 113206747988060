import { writable, derived } from "svelte/store";
import { customAlphabet } from "nanoid";

import { storedWritable } from "./ui.js";
import {
  createType as defaultType,
  createGroup as defaultGroup,
} from "@local/lamina-core";
import creatRecordList from "./record-list";
import { api, shareDraft, processRfq } from "src/api";
import { incrementName } from "@local/extensions/identifiers/name.js";
import {
  selected,
  sortList,
} from "@local/extensions/collections/sortable-list.js";
import { profile } from "./auth.js";
import { myRfqsOnly } from "./ui.js";

const nanoid = customAlphabet("23456789ABCDEFGHJKLMNPQRSTUVWXYZ", 8);

let $profile;
profile.subscribe((value) => {
  $profile = value;
});

const ls = JSON.parse(localStorage.getItem("jobs")) || {};
const stored = {
  selected_job_ids: [],
  ...ls,
};
localStorage.setItem("jobs", JSON.stringify(stored));

let $jobs;

export async function storeJob(job, project_type = "unconstrained") {
  const { created_at, ...j } = job;

  // First insert the job
  const { error: jobInsertError, data } = await api
    .from("jobs")
    .insert(j)
    .select("*, organization:organization_id(*)")
    .single();
  if (jobInsertError) throw jobInsertError;

  // Add a new group to the job
  const g = defaultGroup(j.organization_id, j.id);
  g.project_type = project_type;

  if (project_type === "product") {
    g.seller_reference = `QT-${nanoid(4)}-${nanoid(4)}`;
  }

  const { error: groupInsertError } = await api.from("groups").insert([g]);
  if (groupInsertError) throw jobInsertError;

  // Make this new group the "current_group"
  const { error: updateJobError } = await api
    .from("jobs")
    .update({ current_group_id: g.id })
    .eq("id", j.id);
  if (updateJobError) throw updateJobError;

  // Add a default type to the group
  if (g.project_type !== "product") {
    const t = defaultType(g.id);
    const { error: typeInsertError } = await api.from("types").insert([t]);
    if (typeInsertError) throw typeInsertError;
  }

  return j;
}

export async function cloneJob(group_id, profile_id = null) {
  const { data: group, error: ge } = await api
    .from("groups")
    .select("*,job:job_id(*)")
    .eq("id", group_id)
    .single();
  if (ge) throw ge;

  const { data: existingJobs, error: eje } = await api
    .from("jobs")
    .select("*")
    .eq("organization_id", group.organization_id);
  if (eje) throw eje;

  const { error, data: jobid } = await api.rpc("clone_revision_to_new_job", {
    group_id,
  });
  if (error) throw error;

  // Increment the name of the new job
  const sibs = existingJobs.map((j) => j.name || "");
  const newName = incrementName(group.job.name, sibs);

  if (group.project_type === "product") {
    await api
      .from("groups")
      .update({
        seller_reference: `QT-${nanoid(4)}-${nanoid(4)}`,
      })
      .eq("job_id", jobid);
  }

  await api
    .from("jobs")
    .update({ name: newName, archived_at: null, assigned_to_id: profile_id })
    .eq("id", jobid);

  return jobid;
}

function createJobs() {
  const {
    subscribe,
    load,
    loadOne,
    updateOne,
    unload,
    insert,
    unloadOne,
    sort,
  } = creatRecordList();

  subscribe((value) => ($jobs = value));

  return {
    subscribe,
    load,
    loadOne,
    unloadOne,
    unload,
    sort,

    // Retrieves records from DB
    async fetch({ limit = null, page = 0 } = {}) {
      try {
        let query = api
          .from("job_list_view")
          .select("*")
          .eq("organization_id", $profile.organization_id)
          .is("archived_at", null)
          .order("created_at", { ascending: false });

        if ($profile?.user_role === "product_user") {
          query = query.eq("project_type", "product");
        }

        if (limit) {
          query = query.limit(limit);
        }

        const { data, error } = await query;

        if (error) throw error;
        load(data);
      } catch (error) {
        console.log(error);
      }
    },

    async fetchById(...ids) {
      try {
        const { data, error } = await api
          .from("job_list_view")
          .select("*")
          .in("id", ids);

        if (error) throw error;
        insert(data);
      } catch (error) {
        console.log(error);
      }
    },

    updateProp(jobid, prop, value) {
      try {
        updateOne(jobid, { [prop]: value });
      } catch (error) {
        console.log(error);
      }
    },

    async cloneRevisionToNewJob(group_id, profile_id) {
      try {
        const jobid = await cloneJob(group_id, profile_id);
        await this.fetchOne(jobid, 0);
        return jobid;
      } catch (error) {
        console.log(error);
      }
    },

    async cloneRevisionToMyOrg(group_id, profile_id = null) {
      try {
        const { data: jobId, error } = await api.rpc("clone_revision_to_org", {
          group_id,
          org_id: $profile.organization_id,
        });
        if (error) throw error;

        const { data: newJob, error: njErr } = await api
          .from("job_list_view")
          .select("*")
          .eq("id", jobId)
          .single();

        if (njErr) throw njErr;

        // Increment the name of the new job
        const sibs = $jobs.order.map((id) => $jobs[id].name || "");
        const newName = incrementName(newJob.name, sibs);
        await api
          .from("jobs")
          .update({
            name: newName,
            archived_at: null,
            assigned_to_id: profile_id,
          })
          .eq("id", jobId);
        if (newJob.project_type === "product") {
          await api
            .from("groups")
            .update({ seller_reference: `QT-${nanoid(4)}-${nanoid(4)}` })
            .eq("job_id", jobId);
        }

        await this.fetchOne(jobId, 0);
        return jobId;
      } catch (error) {
        console.log(error);
      }
    },

    async shareWithOrg(job_id, buyer_id, contact_id) {
      const draft = await shareDraft({
        job_id,
        buyer_id,
        contact_id,
      });

      if (draft.error) throw draft.error;
      return draft.data;
    },

    async createRfqForOrg(job_id, buyer_id, request_for, buyer_reference) {
      const rfq = await processRfq({
        job_id,
        buyer_id,
        request_for,
        buyer_reference,
      });
      if (rfq.error) throw rfq.error;

      unload(job_id);
      return rfq.data;
    },

    // Retrieves a single record from DB
    async fetchOne(jobid, sortPosition) {
      try {
        const { data, error } = await api
          .from("job_list_view")
          .select("*")
          .eq("id", jobid)
          .single();
        if (error) throw error;

        loadOne(data, sortPosition);
      } catch (error) {
        console.log(error);
      }
    },

    // Persists a new job in DB
    async add(job, project_type = "unconstrained") {
      try {
        const j = await storeJob(job, project_type);
        await this.fetchOne(j.id, 0);
        return j;
      } catch (error) {
        console.log(error);
      }
    },

    // Clone a job within this org
    async clone(job_id) {
      try {
        const { data: newId, error } = await api.rpc("clone_job", { job_id });
        if (error) throw error;

        const name = $jobs[job_id].name || "";

        // Increment the name of the new job
        const sibs = $jobs.order.map((id) => $jobs[id].name || "");
        const newName = incrementName(name, sibs);
        await api.from("jobs").update({ name: newName }).eq("id", newId);

        await this.fetchOne(newId, 0);
        return newId;
      } catch (e) {
        console.log(e);
      }
    },

    async remove(id) {
      try {
        const { error } = await api.from("jobs").delete().eq("id", id);

        if (error) throw error;
        unload(id);
      } catch (error) {
        console.log(error);
      }
    },

    async archive(...ids) {
      try {
        const { error } = await api
          .from("jobs")
          .update({ archived_at: new Date() })
          .in("id", ids);

        if (error) throw error;

        unload(...ids);
      } catch (error) {
        console.log(error);
      }
    },

    async unarchive(id) {
      try {
        const j = await api
          .from("jobs")
          .update({ archived_at: null })
          .eq("id", id);

        if (j.error) throw j.error;

        const jl = await api
          .from("job_list_view")
          .select("*")
          .eq("id", id)
          .single();

        if (jl.error) throw jl.error;

        loadOne(jl.data);
      } catch (error) {}
    },

    async spin(id) {
      try {
        updateOne(id, { spinning: true });
      } catch (error) {
        console.log(error);
      }
    },
  };
}

export const jobs = createJobs();

export async function fetchJob(job_id) {
  try {
    const { data, error } = await api
      .from("jobs")
      .select(
        `
      *,
      current_group: current_group_id(*,items!items_group_id_fkey(*),types!types_group_id_fkey(*)),
      organization: organization_id (name),
      groups!groups_job_id_fkey(*,shares!shares_group_id_fkey(*,user:user_id(email)))`,
      )
      .eq("id", job_id)
      .order("version", { foreignTable: "groups" })
      .order("created_at", { foreignTable: "current_group.items" })
      .order("created_at", { foreignTable: "current_group.types" })
      .maybeSingle();
    if (error) throw error;

    return data;
  } catch (e) {
    console.log(e);
  }
}

export const jobFilter = writable("");
export const jobSortBy = writable("created_at");
export const jobSortDirection = writable("desc");

export const filteredJobs = derived(
  [jobs, jobFilter, jobSortBy, jobSortDirection, myRfqsOnly, profile],
  ([$jobs, $jobFilter, $jobSortBy, $jobSortDirection, $mineOnly, $profile]) => {
    const ids = $jobs?.order.filter((id) => {
      const job = $jobs[id];

      if (
        $mineOnly &&
        job.assigned_to_id &&
        job.assigned_to_id !== $profile.id
      ) {
        return false;
      }

      const str = $jobFilter.toLowerCase();
      const name = (job.name || "").toLowerCase();
      const location = (job.location || "").toLowerCase();
      const seller_reference = (job.seller_reference || "").toLowerCase();
      if (name.includes(str)) return true;
      if (location.includes(str)) return true;
      if (seller_reference.includes(str)) return true;
      return false;
    });

    const f = selected($jobs, ids);

    sortList(f, { property: $jobSortBy, direction: $jobSortDirection });
    return f;
  },
);

export function draftList(filteredJobs) {
  return derived(filteredJobs, ($list) => $list.order.map((id) => $list[id]));
}

export const selectedJobIds = storedWritable(
  "selected_job_ids",
  stored.selected_job_ids,
  "jobs",
);
